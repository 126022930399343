<template>
    <div>
        <div class="py-3">
            <h4>PROFIL NPWPD</h4>
        </div>
        <div>
            <b-form
                @submit.prevent="editMode ? update(user.npwpd_id) : store()"
                
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Jenis
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            :options="['Perorangan', 'Badan Usaha']"
                            v-model="form.jenis"
                            @input="inputJenis(form.jenis)"
                            placeholder="Pilih Jenis"
                            class="bg-white"
                            :disabled="disableJenis"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('jenis')"
                            class="form-text text-danger"
                            >{{ errors.jenis[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1" v-show="disableBentuk">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Bentuk Badan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="bentukBadanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.bentuk_badan_id"
                            placeholder="Pilih Bentuk Badan"
                            class="bg-white"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('bentuk_badan_id')"
                            class="form-text text-danger"
                            >{{ errors.bentuk_badan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        ><span v-if="disableBentuk">
                            NIB
                        </span>
                        <span v-else>
                            NIK
                        </span>
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('no_induk'),
                            }"
                            v-model="form.no_induk"
                            placeholder="Masukkan NIK"
                        />
                        <em
                            v-if="form.errors.has('no_induk')"
                            class="form-text text-danger"
                            >{{ errors.no_induk[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nama Wajib Pajak
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('nama'),
                            }"
                            v-model="form.nama"
                            placeholder="Masukkan Nama Subjek Pajak"
                            style="text-transform: uppercase;"
                        />
                        <div>
                            <small class="font-weight-bold">Perhatian:</small>
                            <div>
                                <small>
                                    1. Masukkan hanya nama badan usaha (contoh: PT. Indonesia Sejahtera Bersatu menjadi Indonesia Sejahtera Bersatu).
                                </small>
                            </div>
                            <div>
                                <small>
                                    2. Masukkan ejaan sesuai dengan NIB (jika Badan Usaha) atau NIK (jika Perorangan).
                                </small>
                            </div>
                        </div>
                        <em
                            v-if="form.errors.has('nama')"
                            class="form-text text-danger"
                            >{{ errors.nama[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor HP
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('nomp'),
                            }"
                            v-model="form.nomp"
                            placeholder="Masukkan Nomor Handphone"
                        />
                        <em
                            v-if="form.errors.has('nomp')"
                            class="form-text text-danger"
                            >{{ errors.nomp[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Nomor Telepon
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('notelepon'),
                            }"
                            v-model="form.notelepon"
                            placeholder="Masukkan Nomor Telepon"
                        />
                        <em
                            v-if="form.errors.has('notelepon')"
                            class="form-text text-danger"
                            >{{ errors.notelepon[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Email
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="email"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('email'),
                            }"
                            v-model="form.email"
                            placeholder="Masukkan Alamat Email"
                        />
                        <em
                            v-if="form.errors.has('email')"
                            class="form-text text-danger"
                            >{{ errors.email[0] }}</em
                        >
                    </div>
                </div>
                <div class="py-3">
                    <h4>ALAMAT</h4>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Alamat
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('alamat'),
                            }"
                            v-model="form.alamat"
                            placeholder="Masukkan Alamat"
                        />
                        <em
                            v-if="form.errors.has('alamat')"
                            class="form-text text-danger"
                            >{{ errors.alamat[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Provinsi
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="provinsiOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.provinsi"
                            @input="onProvinsi(input.provinsi)"
                            placeholder="Pilih Provinsi"
                        >
                        </v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kota/Kabupaten
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kotaOptions"
                            v-model="input.kabkota"
                            :reduce="(nama) => nama.id"
                            @input="onKabKota(input.kabkota)"
                            placeholder="Pilih Kabupaten/Kota"
                            class="bg-white"
                        ></v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kecamatan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kecamatanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="input.kecamatan"
                            @input="onKecamatan(input.kecamatan)"
                            placeholder="Pilih Kecamatan"
                        ></v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kelurahan
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <v-select
                            label="nama"
                            :options="kelurahanOptions"
                            :reduce="(nama) => nama.id"
                            v-model="form.wilayah_kelurahan_id"
                            placeholder="Pilih Kelurahan"
                            class="bg-white"
                        ></v-select>
                        <em
                            v-if="form.errors.has('wilayah_kelurahan_id')"
                            class="form-text text-danger"
                            >{{ errors.wilayah_kelurahan_id[0] }}</em
                        >
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Kodepos
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-4">
                        <input
                            type="text"
                            :class="{
                                'form-control': true,
                                'is-invalid': form.errors.has('kodepos'),
                            }"
                            v-model="form.kodepos"
                            placeholder="Masukkan Kodepos"
                        />
                        <em
                            v-if="form.errors.has('kodepos')"
                            class="form-text text-danger"
                            >{{ errors.kodepos[0] }}</em
                        >
                    </div>
                </div>
                <hr />
                <div>
                    <span>
                        <b-button
                            variant="primary"
                            type="submit"
                            class="px-4"
                            :disabled="isDisable"
                        >
                            <span v-if="editMode">
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i class="fa fa-edit"></i>
                                </span>
                                Edit
                            </span>
                            <span v-else>
                                <span v-if="isLoading" class="px-1">
                                    <b-spinner
                                        variant="primary"
                                        small
                                    ></b-spinner>
                                </span>
                                <span v-else class="px-1">
                                    <i class="fa fa-save"></i>
                                </span>
                                Simpan
                            </span>
                        </b-button>
                    </span>
                    <span class="float-right">
                        <b-button variant="secondary" v-if="!editMode">
                            <i class="fa fa-redo-alt"></i> Reset
                        </b-button>
                    </span>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import axios from "axios";
export default {
    props: ["detailProfil"],
    data() {
        return {
            form: new Form({
                bentuk_badan_id: "",
                jenis: "",
                nama: "",
                nomp: "",
                no_induk: "",
                notelepon: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: null,
                kodepos: "",
            }),
            input: {
                provinsi: "",
                kabkota: "",
                kecamatan: "",
            },
            bentukBadanOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],
            isLoading: false,
            editMode: false,
            isDisable: false,
            errors: [],
            disableBentuk: true,
            user: JSON.parse(localStorage.getItem("user")),
            detail: [],
            disableJenis: false,
        };
    },
    mounted() {
        this.getBentukBadan();
        this.getProvinsi();
        if (this.user.npwpd_id !== null) {
            this.detailNpwpd(this.user.npwpd_id);
        }
    },
    methods: {
        // when npwpd id exist
        detailNpwpd(id) {
            axios
                .get("/api/wajib-pajak/npwpd/" + id)
                .then((response) => {
                    this.detail = response.data.data;
                    if (this.detail.status_registrasi_id !== 5) {
                        this.editMode = true;
                        this.form.fill(this.detail);
                        if (this.form.jenis <= 40) {
                            this.form.jenis = "Badan Usaha";
                            this.disableBentuk = true;
                            this.disableJenis = true;
                        } else if (this.form.jenis >= 41) {
                            this.form.jenis = "Perorangan";
                            this.isJenis = false;
                            this.disableBentuk = false;
                            this.disableJenis = true;
                        }
                        // this.form.wilayah_kelurahan_id = parseInt(this.detail.wilayah_kelurahan_id)
                        this.input.provinsi = this.detail.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id
                        this.getKabupaten(this.input.provinsi)
                        this.input.kabkota = this.detail.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id
                        this.getKecamatan(this.input.kabkota)
                        this.input.kecamatan = this.detail.wilayah_kelurahan.wilayah_kecamatan_id
                        this.getKelurahan(this.input.kecamatan)
                    } 
                    // console.log(this.detail);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // store data
        store() {
            this.isLoading = true;
            this.form.nama = this.form.nama.toUpperCase()
            this.form
                .post("/api/wajib-pajak/npwpd")
                .then((response) => {
                    if (this.form.successful) {
                        if (this.form.jenis === "Perorangan") {
                            this.$store.dispatch("isEventKirim", false);
                            this.isJenis = false;
                            this.disableBentuk = false;
                            this.disableJenis = true;
                        } else {
                            this.isJenis = false;
                            this.disableBentuk = true;
                            this.disableJenis = true;
                        }
                        // this.isDisable = true
                        this.editMode = true;
                        this.$swal({
                            icon: "success",
                            title: "Berhasil Mendaftarkan Data Wajib Pajak",
                            text:
                                "Anda dapat mengakses aplikasi apabila data anda disetujui.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonClass: "btn btn-primary",
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.user.npwpd_id = response.data.data.id;
                                localStorage.setItem(
                                    "user",
                                    JSON.stringify(this.user)
                                );
                                this.$store.dispatch("updateNpwpd", response.data.data.id)
                                this.editMode = true;
                                this.isLoading = false;
                                if (
                                    response.data.data.status_registrasi_id < 5
                                ) {
                                    this.formShow = true;
                                }
                            }
                        });
                        /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    // this.isDisable = false
                    this.editMode = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    } else {
                        console.log(error.response.data.message);
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            html:
                                "Silahkan hubungi bagian maintenance. <br> ERROR: " +
                                error.response.data.message,
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "OKE",
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000,
                        });
                    }
                    console.log(error);
                });
        },
        // update data
        update(id) {
            this.isLoading = true;
            this.form.nama = this.form.nama.toUpperCase()
            this.form
                .put("/api/wajib-pajak/npwpd/" + id)
                .then((response) => {
                    if (this.form.successful) {
                        this.isLoading = false
                        if (this.form.jenis === "Perorangan") {
                            this.$store.dispatch("isEventKirim", false);
                            this.isJenis = false;
                            this.disableBentuk = false;
                            this.disableJenis = true;
                        } else {
                            this.isJenis = false;
                            this.disableBentuk = false;
                            this.disableJenis = true;
                        }
                        this.$swal({
                            icon: "success",
                            title: "Perubahan Data Wajib Pajak Berhasil",
                            text:
                                "Anda dapat mengakses aplikasi apabila data anda disetujui.",
                            type: "success",
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            confirmButtonClass: "btn btn-primary",
                            confirmButtonText: "OKE",
                            showCloseButton: true,
                            timer: 5000,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.user.npwpd_id = response.data.data.id;
                                localStorage.setItem(
                                    "user",
                                    JSON.stringify(this.user)
                                );
                                this.$store.dispatch("updateNpwpd", response.data.data.id)
                                this.isLoading = false;
                                if (
                                    response.data.data.status_registrasi_id < 5
                                ) {
                                    this.formShow = true;
                                }
                            }
                        });
                        /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.message = error.response.data.message;
                    } else if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    } else {
                        console.log(error.response.data.message);
                        this.$swal({
                            icon: "error",
                            title: "Terjadi Masalah",
                            html:
                                "Silahkan hubungi bagian maintenance. <br> ERROR: " +
                                error.response.data.message,
                            confirmButtonClass: "btn btn-info",
                            showConfirmButton: true,
                            showCloseButton: true,
                            confirmButtonText: "OKE",
                            confirmButtonClass: "btn btn-primary",
                            timer: 5000,
                        });
                    }
                    console.log(error);
                });
        },
        // bentuk badan
        getBentukBadan() {
            axios
                .get("/api/wajib-pajak/bentuk-badan/getoption")
                .then((response) => {
                    this.bentukBadanOptions = response.data.data;
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.error == 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        inputJenis(value) {
            if (value === "Perorangan") {
                this.$store.dispatch("isEventDisable", true);
                this.disableBentuk = false;
            } else {
                this.$store.dispatch("isEventDisable", false);
                this.disableBentuk = true;
            }
        },
        //Wilayah
        // saat provinsi di input
        onProvinsi(id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = [];
                this.input.kota = null;
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKabupaten(id);
            }
        },
        // saat kab./kota di input
        onKabKota(id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKecamatan(id);
            }
        },

        // saat kab./kota di input
        onKecamatan(id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKelurahan(id);
            }
        },

        // Provinsi
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then((response) => {
                    this.provinsiOptions = response.data.data;
                    /* console.log(this.provinsiOptions) */
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kabupaten
        getKabupaten(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kotaOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kecamatan
        getKecamatan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" +
                        id
                )
                .then((response) => {
                    /*  console.log(response.data.data) */
                    this.kecamatanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // Kelurahan
        getKelurahan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kelurahanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // reset
        reset() {
            this.form.reset();
            this.input.provinsi = null;
            this.input.kota = null;
            this.input.kecamatan = null;
            this.input.kabupaten = null;
        },
    },
};
</script>
